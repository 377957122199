'use strict'

exports.__esModule = true

var _slider = require('./slider')

var _slider2 = _interopRequireDefault(_slider)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

exports.default = _slider2.default
