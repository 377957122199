import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import MainSlider from '../components/slider'

const IndexPage = ({ data, transition, location }) => {
	console.log(data)
	return (
		<Layout location={location}>
			<div style={transition && transition.style}>
				<MainSlider slides={data.allMarkdownRemark.edges}/>
			</div>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
  query {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/.*(/projects/).*(/)(showroom.md)/"}}, sort: {fields: fileAbsolutePath, order: ASC}) {
    edges {
      node {
        id
        html
        fields {
          slug
        }
        frontmatter {
          title
          navTitle
          linkTitle
          link
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 85) {
                aspectRatio
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
}
`
