exports.__esModule = true
exports.canUseDOM = exports.slidesOnLeft = exports.slidesOnRight = exports.siblingDirection = exports.getTotalSlides = exports.getPostClones = exports.getPreClones = exports.getTrackLeft = exports.getTrackAnimateCSS = exports.getTrackCSS = exports.checkSpecKeys = exports.getSlideCount = exports.checkNavigable = exports.getNavigableIndexes = exports.swipeEnd = exports.swipeMove = exports.swipeStart = exports.keyHandler = exports.changeSlide = exports.slideHandler = exports.initializedState = exports.extractObject = exports.canGoNext = exports.getSwipeDirection = exports.getHeight = exports.getWidth = exports.lazySlidesOnRight = exports.lazySlidesOnLeft = exports.lazyEndIndex = exports.lazyStartIndex = exports.getRequiredLazySlides = exports.getOnDemandLazySlides = undefined

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i]
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

var _react = require('react')

var _react2 = _interopRequireDefault(_react)

var _reactDom = require('react-dom')

var _reactDom2 = _interopRequireDefault(_reactDom)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

var getOnDemandLazySlides = (exports.getOnDemandLazySlides = function getOnDemandLazySlides(
  spec
) {
  var onDemandSlides = []
  var startIndex = lazyStartIndex(spec)
  var endIndex = lazyEndIndex(spec)
  for (var slideIndex = startIndex; slideIndex < endIndex; slideIndex++) {
    if (spec.lazyLoadedList.indexOf(slideIndex) < 0) {
      onDemandSlides.push(slideIndex)
    }
  }
  return onDemandSlides
})

// return list of slides that need to be present
var getRequiredLazySlides = (exports.getRequiredLazySlides = function getRequiredLazySlides(
  spec
) {
  var requiredSlides = []
  var startIndex = lazyStartIndex(spec)
  var endIndex = lazyEndIndex(spec)
  for (var slideIndex = startIndex; slideIndex < endIndex; slideIndex++) {
    requiredSlides.push(slideIndex)
  }
  return requiredSlides
})

// startIndex that needs to be present
var lazyStartIndex = (exports.lazyStartIndex = function lazyStartIndex(spec) {
  return spec.currentSlide - lazySlidesOnLeft(spec)
})
var lazyEndIndex = (exports.lazyEndIndex = function lazyEndIndex(spec) {
  return spec.currentSlide + lazySlidesOnRight(spec)
})
var lazySlidesOnLeft = (exports.lazySlidesOnLeft = function lazySlidesOnLeft(
  spec
) {
  return spec.centerMode
    ? Math.floor(spec.slidesToShow / 2) +
        (parseInt(spec.centerPadding) > 0 ? 1 : 0)
    : 0
})
var lazySlidesOnRight = (exports.lazySlidesOnRight = function lazySlidesOnRight(
  spec
) {
  return spec.centerMode
    ? Math.floor((spec.slidesToShow - 1) / 2) +
        1 +
        (parseInt(spec.centerPadding) > 0 ? 1 : 0)
    : spec.slidesToShow
})

// get width of an element
var getWidth = (exports.getWidth = function getWidth(elem) {
  return (elem && elem.offsetWidth) || 0
})
var getHeight = (exports.getHeight = function getHeight(elem) {
  return (elem && elem.offsetHeight) || 0
})
var getSwipeDirection = (exports.getSwipeDirection = function getSwipeDirection(
  touchObject
) {
  var verticalSwiping =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false

  var xDist, yDist, r, swipeAngle
  xDist = touchObject.startX - touchObject.curX
  yDist = touchObject.startY - touchObject.curY
  r = Math.atan2(yDist, xDist)
  swipeAngle = Math.round(r * 180 / Math.PI)
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle)
  }
  if (
    (swipeAngle <= 45 && swipeAngle >= 0) ||
    (swipeAngle <= 360 && swipeAngle >= 315)
  ) {
    return 'left'
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return 'right'
  }
  if (verticalSwiping === true) {
    if (swipeAngle >= 35 && swipeAngle <= 135) {
      return 'up'
    } else {
      return 'down'
    }
  }

  return 'vertical'
})

// whether or not we can go next
var canGoNext = (exports.canGoNext = function canGoNext(spec) {
  var canGo = true
  if (!spec.infinite) {
    if (spec.centerMode && spec.currentSlide >= spec.slideCount - 1) {
      canGo = false
    } else if (
      spec.slideCount <= spec.slidesToShow ||
      spec.currentSlide >= spec.slideCount - spec.slidesToShow
    ) {
      canGo = false
    }
  }
  return canGo
})

// given an object and a list of keys, return new object with given keys
var extractObject = (exports.extractObject = function extractObject(
  spec,
  keys
) {
  var newObject = {}
  keys.forEach(function(key) {
    return (newObject[key] = spec[key])
  })
  return newObject
})

// get initialized state
var initializedState = (exports.initializedState = function initializedState(
  spec
) {
  // spec also contains listRef, trackRef
  var slideCount = _react2.default.Children.count(spec.children)
  var listWidth = Math.ceil(
    getWidth(_reactDom2.default.findDOMNode(spec.listRef))
  )
  var trackWidth = Math.ceil(
    getWidth(_reactDom2.default.findDOMNode(spec.trackRef))
  )
  var slideWidth = void 0
  if (!spec.vertical) {
    var centerPaddingAdj = spec.centerMode && parseInt(spec.centerPadding) * 2
    if (
      typeof spec.centerPadding === 'string' &&
      spec.centerPadding.slice(-1) === '%'
    ) {
      centerPaddingAdj *= listWidth / 100
    }
    slideWidth = Math.ceil((listWidth - centerPaddingAdj) / spec.slidesToShow)
  } else {
    slideWidth = listWidth
  }
  var slideHeight =
    _reactDom2.default.findDOMNode(spec.listRef) &&
    getHeight(
      _reactDom2.default
        .findDOMNode(spec.listRef)
        .querySelector('[data-index="0"]')
    )
  var listHeight = slideHeight * spec.slidesToShow
  var currentSlide =
    spec.currentSlide === undefined ? spec.initialSlide : spec.currentSlide
  if (spec.rtl && spec.currentSlide === undefined) {
    currentSlide = slideCount - 1 - spec.initialSlide
  }
  var lazyLoadedList = spec.lazyLoadedList || []
  var slidesToLoad = getOnDemandLazySlides(
    { currentSlide: currentSlide, lazyLoadedList: lazyLoadedList },
    spec
  )
  lazyLoadedList.concat(slidesToLoad)

  var state = {
    slideCount: slideCount,
    slideWidth: slideWidth,
    listWidth: listWidth,
    trackWidth: trackWidth,
    currentSlide: currentSlide,
    slideHeight: slideHeight,
    listHeight: listHeight,
    lazyLoadedList: lazyLoadedList,
  }

  if (spec.autoplaying === null && spec.autoplay) {
    state['autoplaying'] = 'playing'
  }

  return state
})

var slideHandler = (exports.slideHandler = function slideHandler(spec) {
  var waitForAnimate = spec.waitForAnimate,
    animating = spec.animating,
    fade = spec.fade,
    infinite = spec.infinite,
    index = spec.index,
    slideCount = spec.slideCount,
    lazyLoadedList = spec.lazyLoadedList,
    lazyLoad = spec.lazyLoad,
    currentSlide = spec.currentSlide,
    centerMode = spec.centerMode,
    slidesToScroll = spec.slidesToScroll,
    slidesToShow = spec.slidesToShow,
    useCSS = spec.useCSS

  if (waitForAnimate && animating) return {}
  var animationSlide = index,
    finalSlide = void 0,
    animationLeft = void 0,
    finalLeft = void 0
  var state = {},
    nextState = {}
  if (fade) {
    if (!infinite && (index < 0 || index >= slideCount)) return {}
    if (index < 0) {
      animationSlide = index + slideCount
    } else if (index >= slideCount) {
      animationSlide = index - slideCount
    }
    if (lazyLoad && lazyLoadedList.indexOf(animationSlide) < 0) {
      lazyLoadedList.push(animationSlide)
    }
    state = {
      animating: true,
      currentSlide: animationSlide,
      lazyLoadedList: lazyLoadedList,
    }
    nextState = { animating: false }
  } else {
    finalSlide = animationSlide
    if (animationSlide < 0) {
      finalSlide = animationSlide + slideCount
      if (!infinite) finalSlide = 0
      else if (slideCount % slidesToScroll !== 0)
        finalSlide = slideCount - slideCount % slidesToScroll
    } else if (!canGoNext(spec) && animationSlide > currentSlide) {
      animationSlide = finalSlide = currentSlide
    } else if (centerMode && animationSlide >= slideCount) {
      animationSlide = infinite ? slideCount : slideCount - 1
      finalSlide = infinite ? 0 : slideCount - 1
    } else if (animationSlide >= slideCount) {
      finalSlide = animationSlide - slideCount
      if (!infinite) finalSlide = slideCount - slidesToShow
      else if (slideCount % slidesToScroll !== 0) finalSlide = 0
    }
    animationLeft = getTrackLeft(
      _extends({}, spec, { slideIndex: animationSlide })
    )
    finalLeft = getTrackLeft(_extends({}, spec, { slideIndex: finalSlide }))
    if (!infinite) {
      if (animationLeft === finalLeft) animationSlide = finalSlide
      animationLeft = finalLeft
    }
    lazyLoad &&
      lazyLoadedList.concat(
        getOnDemandLazySlides(
          _extends({}, spec, { currentSlide: animationSlide })
        )
      )
    if (!useCSS) {
      state = {
        currentSlide: finalSlide,
        trackStyle: getTrackCSS(_extends({}, spec, { left: finalLeft })),
        lazyLoadedList: lazyLoadedList,
      }
    } else {
      state = {
        animating: true,
        currentSlide: finalSlide,
        trackStyle: getTrackAnimateCSS(
          _extends({}, spec, { left: animationLeft })
        ),
        lazyLoadedList: lazyLoadedList,
      }
      nextState = {
        animating: false,
        currentSlide: finalSlide,
        trackStyle: getTrackCSS(_extends({}, spec, { left: finalLeft })),
        swipeLeft: null,
      }
    }
  }
  return { state: state, nextState: nextState }
})

var changeSlide = (exports.changeSlide = function changeSlide(spec, options) {
  var indexOffset, previousInt, slideOffset, unevenOffset, targetSlide
  var slidesToScroll = spec.slidesToScroll,
    slidesToShow = spec.slidesToShow,
    slideCount = spec.slideCount,
    currentSlide = spec.currentSlide,
    lazyLoad = spec.lazyLoad,
    infinite = spec.infinite

  unevenOffset = slideCount % slidesToScroll !== 0
  indexOffset = unevenOffset ? 0 : (slideCount - currentSlide) % slidesToScroll

  if (options.message === 'previous') {
    slideOffset =
      indexOffset === 0 ? slidesToScroll : slidesToShow - indexOffset
    targetSlide = currentSlide - slideOffset
    if (lazyLoad && !infinite) {
      previousInt = currentSlide - slideOffset
      targetSlide = previousInt === -1 ? slideCount - 1 : previousInt
    }
  } else if (options.message === 'next') {
    slideOffset = indexOffset === 0 ? slidesToScroll : indexOffset
    targetSlide = currentSlide + slideOffset
    if (lazyLoad && !infinite) {
      targetSlide = (currentSlide + slidesToScroll) % slideCount + indexOffset
    }
  } else if (options.message === 'dots') {
    // Click on dots
    targetSlide = options.index * options.slidesToScroll
    if (targetSlide === options.currentSlide) {
      return null
    }
  } else if (options.message === 'children') {
    // Click on the slides
    targetSlide = options.index
    if (targetSlide === options.currentSlide) {
      return null
    }
    if (infinite) {
      var direction = siblingDirection(
        _extends({}, spec, { targetSlide: targetSlide })
      )
      if (targetSlide > options.currentSlide && direction === 'left') {
        targetSlide = targetSlide - slideCount
      } else if (targetSlide < options.currentSlide && direction === 'right') {
        targetSlide = targetSlide + slideCount
      }
    }
  } else if (options.message === 'index') {
    targetSlide = Number(options.index)
    if (targetSlide === options.currentSlide) {
      return null
    }
  }
  return targetSlide
})
var keyHandler = (exports.keyHandler = function keyHandler(
  e,
  accessibility,
  rtl
) {
  if (e.target.tagName.match('TEXTAREA|INPUT|SELECT') || !accessibility)
    return ''
  if (e.keyCode === 37) return rtl ? 'next' : 'previous'
  if (e.keyCode === 39) return rtl ? 'previous' : 'next'
  return ''
})

var swipeStart = (exports.swipeStart = function swipeStart(
  e,
  swipe,
  draggable
) {
  // e.target.tagName === "IMG" && e.preventDefault();
  if (!swipe || (!draggable && e.type.indexOf('mouse') !== -1)) return ''
  return {
    dragging: true,
    touchObject: {
      startX: e.touches ? e.touches[0].pageX : e.clientX,
      startY: e.touches ? e.touches[0].pageY : e.clientY,
      curX: e.touches ? e.touches[0].pageX : e.clientX,
      curY: e.touches ? e.touches[0].pageY : e.clientY,
    },
  }
})
var swipeMove = (exports.swipeMove = function swipeMove(e, spec) {
  // spec also contains, trackRef and slideIndex
  var scrolling = spec.scrolling,
    animating = spec.animating,
    vertical = spec.vertical,
    swipeToSlide = spec.swipeToSlide,
    verticalSwiping = spec.verticalSwiping,
    rtl = spec.rtl,
    currentSlide = spec.currentSlide,
    edgeFriction = spec.edgeFriction,
    edgeDragged = spec.edgeDragged,
    onEdge = spec.onEdge,
    swiped = spec.swiped,
    swiping = spec.swiping,
    slideCount = spec.slideCount,
    slidesToScroll = spec.slidesToScroll,
    infinite = spec.infinite,
    touchObject = spec.touchObject,
    swipeEvent = spec.swipeEvent,
    listHeight = spec.listHeight,
    listWidth = spec.listWidth

  if (scrolling) return
  if (animating) return e.preventDefault()
  if (vertical && swipeToSlide && verticalSwiping) e.preventDefault()
  var swipeLeft = void 0,
    state = {}
  var curLeft = getTrackLeft(spec)
  touchObject.curX = e.touches ? e.touches[0].pageX : e.clientX
  touchObject.curY = e.touches ? e.touches[0].pageY : e.clientY
  touchObject.swipeLength = Math.round(
    Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2))
  )
  var verticalSwipeLength = Math.round(
    Math.sqrt(Math.pow(touchObject.curY - touchObject.startY, 2))
  )
  if (!verticalSwiping && !swiping && verticalSwipeLength > 10) {
    return { scrolling: true }
  }
  if (verticalSwiping) touchObject.swipeLength = verticalSwipeLength
  var positionOffset =
    (!rtl ? 1 : -1) * (touchObject.curX > touchObject.startX ? 1 : -1)
  if (verticalSwiping)
    positionOffset = touchObject.curY > touchObject.startY ? 1 : -1

  var dotCount = Math.ceil(slideCount / slidesToScroll)
  var swipeDirection = getSwipeDirection(spec.touchObject, verticalSwiping)
  var touchSwipeLength = touchObject.swipeLength
  if (!infinite) {
    if (
      (currentSlide === 0 && swipeDirection === 'right') ||
      (currentSlide + 1 >= dotCount && swipeDirection === 'left') ||
      (!canGoNext(spec) && swipeDirection === 'left')
    ) {
      touchSwipeLength = touchObject.swipeLength * edgeFriction
      if (edgeDragged === false && onEdge) {
        onEdge(swipeDirection)
        state['edgeDragged'] = true
      }
    }
  }
  if (!swiped && swipeEvent) {
    swipeEvent(swipeDirection)
    state['swiped'] = true
  }
  if (!vertical) {
    if (!rtl) {
      swipeLeft = curLeft + touchSwipeLength * positionOffset
    } else {
      swipeLeft = curLeft - touchSwipeLength * positionOffset
    }
  } else {
    swipeLeft =
      curLeft + touchSwipeLength * (listHeight / listWidth) * positionOffset
  }
  if (verticalSwiping) {
    swipeLeft = curLeft + touchSwipeLength * positionOffset
  }
  state = _extends({}, state, {
    touchObject: touchObject,
    swipeLeft: swipeLeft,
    trackStyle: getTrackCSS(_extends({}, spec, { left: swipeLeft })),
  })
  if (
    Math.abs(touchObject.curX - touchObject.startX) <
    Math.abs(touchObject.curY - touchObject.startY) * 0.8
  ) {
    return state
  }
  if (touchObject.swipeLength > 10) {
    state['swiping'] = true
    e.preventDefault()
  }
  return state
})
var swipeEnd = (exports.swipeEnd = function swipeEnd(e, spec) {
  var dragging = spec.dragging,
    swipe = spec.swipe,
    touchObject = spec.touchObject,
    listWidth = spec.listWidth,
    touchThreshold = spec.touchThreshold,
    verticalSwiping = spec.verticalSwiping,
    listHeight = spec.listHeight,
    currentSlide = spec.currentSlide,
    swipeToSlide = spec.swipeToSlide,
    scrolling = spec.scrolling,
    onSwipe = spec.onSwipe

  if (!dragging) {
    if (swipe) e.preventDefault()
    return {}
  }
  var minSwipe = verticalSwiping
    ? listHeight / touchThreshold
    : listWidth / touchThreshold
  var swipeDirection = getSwipeDirection(touchObject, verticalSwiping)
  // reset the state of touch related state variables.
  var state = {
    dragging: false,
    edgeDragged: false,
    scrolling: false,
    swiping: false,
    swiped: false,
    swipeLeft: null,
    touchObject: {},
  }
  if (scrolling) {
    return state
  }
  if (!touchObject.swipeLength) {
    return state
  }
  if (touchObject.swipeLength > minSwipe) {
    e.preventDefault()
    if (onSwipe) {
      onSwipe(swipeDirection)
    }
    var slideCount = void 0,
      newSlide = void 0
    switch (swipeDirection) {
      case 'left':
      case 'up':
        newSlide = currentSlide + getSlideCount(spec)
        slideCount = swipeToSlide ? checkNavigable(spec, newSlide) : newSlide
        state['currentDirection'] = 0
        break
      case 'right':
      case 'down':
        newSlide = currentSlide - getSlideCount(spec)
        slideCount = swipeToSlide ? checkNavigable(spec, newSlide) : newSlide
        state['currentDirection'] = 1
        break
      default:
        slideCount = currentSlide
    }
    state['triggerSlideHandler'] = slideCount
  } else {
    // Adjust the track back to it's original position.
    var currentLeft = getTrackLeft(spec)
    state['trackStyle'] = getTrackAnimateCSS(
      _extends({}, spec, { left: currentLeft })
    )
  }
  return state
})
var getNavigableIndexes = (exports.getNavigableIndexes = function getNavigableIndexes(
  spec
) {
  var max = spec.infinite ? spec.slideCount * 2 : spec.slideCount
  var breakpoint = spec.infinite ? spec.slidesToShow * -1 : 0
  var counter = spec.infinite ? spec.slidesToShow * -1 : 0
  var indexes = []
  while (breakpoint < max) {
    indexes.push(breakpoint)
    breakpoint = counter + spec.slidesToScroll
    counter += Math.min(spec.slidesToScroll, spec.slidesToShow)
  }
  return indexes
})
var checkNavigable = (exports.checkNavigable = function checkNavigable(
  spec,
  index
) {
  var navigables = getNavigableIndexes(spec)
  var prevNavigable = 0
  if (index > navigables[navigables.length - 1]) {
    index = navigables[navigables.length - 1]
  } else {
    for (var n in navigables) {
      if (index < navigables[n]) {
        index = prevNavigable
        break
      }
      prevNavigable = navigables[n]
    }
  }
  return index
})
var getSlideCount = (exports.getSlideCount = function getSlideCount(spec) {
  var centerOffset = spec.centerMode
    ? spec.slideWidth * Math.floor(spec.slidesToShow / 2)
    : 0
  if (spec.swipeToSlide) {
    var swipedSlide = void 0
    var slickList = _reactDom2.default.findDOMNode(spec.listRef)
    var slides = slickList.querySelectorAll('.slick-slide')
    Array.from(slides).every(function(slide) {
      if (!spec.vertical) {
        if (
          slide.offsetLeft - centerOffset + getWidth(slide) / 2 >
          spec.swipeLeft * -1
        ) {
          swipedSlide = slide
          return false
        }
      } else {
        if (slide.offsetTop + getHeight(slide) / 2 > spec.swipeLeft * -1) {
          swipedSlide = slide
          return false
        }
      }

      return true
    })

    if (!swipedSlide) {
      return 0
    }
    var currentIndex =
      spec.rtl === true
        ? spec.slideCount - spec.currentSlide
        : spec.currentSlide
    var slidesTraversed =
      Math.abs(swipedSlide.dataset.index - currentIndex) || 1
    return slidesTraversed
  } else {
    return spec.slidesToScroll
  }
})

var checkSpecKeys = (exports.checkSpecKeys = function checkSpecKeys(
  spec,
  keysArray
) {
  return keysArray.reduce(function(value, key) {
    return value && spec.hasOwnProperty(key)
  }, true)
    ? null
    : console.error('Keys Missing:', spec)
})

var getTrackCSS = (exports.getTrackCSS = function getTrackCSS(spec) {
  checkSpecKeys(spec, [
    'left',
    'variableWidth',
    'slideCount',
    'slidesToShow',
    'slideWidth',
  ])
  var trackWidth = void 0,
    trackHeight = void 0
  var trackChildren = spec.slideCount + 2 * spec.slidesToShow
  if (!spec.vertical) {
    trackWidth = getTotalSlides(spec) * spec.slideWidth
  } else {
    trackHeight = trackChildren * spec.slideHeight
  }
  var style = {
    opacity: 1,
    transition: '',
    WebkitTransition: '',
  }
  if (spec.useTransform) {
    var WebkitTransform = !spec.vertical
      ? 'translate3d(' + spec.left + 'px, 0px, 0px)'
      : 'translate3d(0px, ' + spec.left + 'px, 0px)'
    var transform = !spec.vertical
      ? 'translate3d(' + spec.left + 'px, 0px, 0px)'
      : 'translate3d(0px, ' + spec.left + 'px, 0px)'
    var msTransform = !spec.vertical
      ? 'translateX(' + spec.left + 'px)'
      : 'translateY(' + spec.left + 'px)'
    style = _extends({}, style, {
      WebkitTransform: WebkitTransform,
      transform: transform,
      msTransform: msTransform,
    })
  } else {
    if (spec.vertical) {
      style['top'] = spec.left
    } else {
      style['left'] = spec.left
    }
  }
  if (spec.fade) style = { opacity: 1 }
  if (trackWidth) style.width = trackWidth
  if (trackHeight) style.height = trackHeight

  // Fallback for IE8
  if (window && !window.addEventListener && window.attachEvent) {
    if (!spec.vertical) {
      style.marginLeft = spec.left + 'px'
    } else {
      style.marginTop = spec.left + 'px'
    }
  }

  return style
})
var getTrackAnimateCSS = (exports.getTrackAnimateCSS = function getTrackAnimateCSS(
  spec
) {
  checkSpecKeys(spec, [
    'left',
    'variableWidth',
    'slideCount',
    'slidesToShow',
    'slideWidth',
    'speed',
    'cssEase',
  ])
  var style = getTrackCSS(spec)
  // useCSS is true by default so it can be undefined
  if (spec.useTransform) {
    style.WebkitTransition =
      '-webkit-transform ' + spec.speed + 'ms ' + spec.cssEase
    style.transition = 'transform ' + spec.speed + 'ms ' + spec.cssEase
  } else {
    if (spec.vertical) {
      style.transition = 'top ' + spec.speed + 'ms ' + spec.cssEase
    } else {
      style.transition = 'left ' + spec.speed + 'ms ' + spec.cssEase
    }
  }
  return style
})
var getTrackLeft = (exports.getTrackLeft = function getTrackLeft(spec) {
  if (spec.unslick) {
    return 0
  }

  checkSpecKeys(spec, [
    'slideIndex',
    'trackRef',
    'infinite',
    'centerMode',
    'slideCount',
    'slidesToShow',
    'slidesToScroll',
    'slideWidth',
    'listWidth',
    'variableWidth',
    'slideHeight',
  ])

  var slideIndex = spec.slideIndex,
    trackRef = spec.trackRef,
    infinite = spec.infinite,
    centerMode = spec.centerMode,
    slideCount = spec.slideCount,
    slidesToShow = spec.slidesToShow,
    slidesToScroll = spec.slidesToScroll,
    slideWidth = spec.slideWidth,
    listWidth = spec.listWidth,
    variableWidth = spec.variableWidth,
    slideHeight = spec.slideHeight,
    fade = spec.fade,
    vertical = spec.vertical

  var slideOffset = 0
  var targetLeft
  var targetSlide
  var verticalOffset = 0

  if (fade || spec.slideCount === 1) {
    return 0
  }

  var slidesToOffset = 0
  if (infinite) {
    slidesToOffset = -getPreClones(spec) // bring active slide to the beginning of visual area
    // if next scroll doesn't have enough children, just reach till the end of original slides instead of shifting slidesToScroll children
    if (
      slideCount % slidesToScroll !== 0 &&
      slideIndex + slidesToScroll > slideCount
    ) {
      slidesToOffset = -(slideIndex > slideCount
        ? slidesToShow - (slideIndex - slideCount)
        : slideCount % slidesToScroll)
    }
    // shift current slide to center of the frame
    if (centerMode) {
      slidesToOffset += parseInt(slidesToShow / 2)
    }
  } else {
    if (
      slideCount % slidesToScroll !== 0 &&
      slideIndex + slidesToScroll > slideCount
    ) {
      slidesToOffset = slidesToShow - slideCount % slidesToScroll
    }
    if (centerMode) {
      slidesToOffset = parseInt(slidesToShow / 2)
    }
  }
  slideOffset = slidesToOffset * slideWidth
  verticalOffset = slidesToOffset * slideHeight

  if (!vertical) {
    targetLeft = slideIndex * slideWidth * -1 + slideOffset
  } else {
    targetLeft = slideIndex * slideHeight * -1 + verticalOffset
  }

  if (variableWidth === true) {
    var targetSlideIndex
    var trackElem = _reactDom2.default.findDOMNode(trackRef)
    targetSlideIndex = slideIndex + getPreClones(spec)
    targetSlide = trackElem && trackElem.childNodes[targetSlideIndex]
    targetLeft = targetSlide ? targetSlide.offsetLeft * -1 : 0
    if (centerMode === true) {
      targetSlideIndex = infinite ? slideIndex + getPreClones(spec) : slideIndex
      targetSlide = trackElem && trackElem.children[targetSlideIndex]
      targetLeft = 0
      for (var slide = 0; slide < targetSlideIndex; slide++) {
        targetLeft -=
          trackElem &&
          trackElem.children[slide] &&
          trackElem.children[slide].offsetWidth
      }
      targetLeft -= parseInt(spec.centerPadding)
      targetLeft += targetSlide && (listWidth - targetSlide.offsetWidth) / 2
    }
  }

  return targetLeft
})

var getPreClones = (exports.getPreClones = function getPreClones(spec) {
  if (spec.unslick || !spec.infinite) {
    return 0
  }
  if (spec.variableWidth) {
    return spec.slideCount
  }
  return spec.slidesToShow + (spec.centerMode ? 1 : 0)
})

var getPostClones = (exports.getPostClones = function getPostClones(spec) {
  if (spec.unslick || !spec.infinite) {
    return 0
  }
  return spec.slideCount
})

var getTotalSlides = (exports.getTotalSlides = function getTotalSlides(spec) {
  return spec.slideCount === 1
    ? 1
    : getPreClones(spec) + spec.slideCount + getPostClones(spec)
})
var siblingDirection = (exports.siblingDirection = function siblingDirection(
  spec
) {
  if (spec.targetSlide > spec.currentSlide) {
    if (spec.targetSlide > spec.currentSlide + slidesOnRight(spec)) {
      return 'left'
    }
    return 'right'
  } else {
    if (spec.targetSlide < spec.currentSlide - slidesOnLeft(spec)) {
      return 'right'
    }
    return 'left'
  }
})

var slidesOnRight = (exports.slidesOnRight = function slidesOnRight(_ref) {
  var slidesToShow = _ref.slidesToShow,
    centerMode = _ref.centerMode,
    rtl = _ref.rtl,
    centerPadding = _ref.centerPadding

  // returns no of slides on the right of active slide
  if (centerMode) {
    var right = (slidesToShow - 1) / 2 + 1
    if (parseInt(centerPadding) > 0) right += 1
    if (rtl && slidesToShow % 2 === 0) right += 1
    return right
  }
  if (rtl) {
    return 0
  }
  return slidesToShow - 1
})

var slidesOnLeft = (exports.slidesOnLeft = function slidesOnLeft(_ref2) {
  var slidesToShow = _ref2.slidesToShow,
    centerMode = _ref2.centerMode,
    rtl = _ref2.rtl,
    centerPadding = _ref2.centerPadding

  // returns no of slides on the left of active slide
  if (centerMode) {
    var left = (slidesToShow - 1) / 2 + 1
    if (parseInt(centerPadding) > 0) left += 1
    if (!rtl && slidesToShow % 2 === 0) left += 1
    return left
  }
  if (rtl) {
    return slidesToShow - 1
  }
  return 0
})

var canUseDOM = (exports.canUseDOM = function canUseDOM() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )
})
