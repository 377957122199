import React from 'react'
import Link from 'gatsby-link'
import styled, { createGlobalStyle } from 'styled-components'
import Slider from '../utils/react-slick'
import Img from 'gatsby-image'

import { media } from '../utils/style-utils'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import prevArrow from '../assets/icons/arrow-left.svg'
import nextArrow from '../assets/icons/arrow-right.svg'
import videoWebm from '../assets/images/neuroth_film.webm'
import videoMp4 from '../assets/images/neuroth_compressed_1.mp4'
import cover from '../assets/images/cover_film.png'

export const GlobalSliderStyle = createGlobalStyle`
	.slick-slide {
		z-index: 998;
		&.slick-active {
			z-index: 999;
		}
	}

	.slick-current {
    color: #2ea9d6;
	}

  .classname {
    height: 100%;
  }

  .owcn {
    height: 100%;
  }

  .fullHeight {
    width: 50%;
    ${media.tabletDown`
      width: 100%;
    `}
  }

  ul {
  	padding-left: 1.75em;
  }

  li:before {
  	content: '■';
    color: #2ea9d6;
    float: left;
    width: 1.5em;
    margin: 0 -1.75em 0 -1.75em;
  }

  li {
  	margin: 0.5em 0;
  }
`

const SlideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  ${media.tabletDown`
		flex-wrap: wrap-reverse;
	`};
`

const NavSlideWrapper = styled.div`
  display: inline-block;
  padding: 0.1em;
  transition: all 0.15s ease-in;
  text-align: left;
  &:hover {
    color: #2ea9d6;
    cursor: pointer;
  }
`
const LowerSliderWrapper = styled.div`
  width: 90%;
  ${media.tabletDown`

	`};
`

const LeftColumn = styled.div`
  width: 32.5%;
  padding-right: 2.5%;
  padding-bottom: 1em;
  ${media.tabletDown`
		width: 100%;
		padding: 0;
		margin-top: 3em;
	`};
`

const RightColumn = styled.div`
  width: 67.5%;
  padding-left: 2.5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.tabletDown`
		width: 100%;
		padding: 0;
	`};
`

const SlideLink = styled(Link)`
  color: #2ea9d6;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5em 0;
  border-bottom: 2px solid #2ea9d6;
  transition: all 0.2s ease-in;
  &:hover {
    color: #f39427;
    border-color: #f39427;
  }
`
const SlideTitle = styled.h1`
  font-size: 3em;
  color: #2ea9d6;
  font-weight: normal;
  margin-bottom: 2rem;
  line-height: 1.3em;
  ${media.mobileOnly`
    font-size: 1.8em;
    margin-bottom: 0.5em;
  `};
  ${media.smallDesktopDown`
    font-size: 2em;
    margin-bottom: 0.5em;
  `};
`

const SliderDescription = styled.div`
  color: #4e4e4d;
  line-height: 1.66em;
  margin-bottom: 2rem;
`

const UpperPart = styled.div`
`

const LowerPart = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tabletDown`
			flex-wrap: wrap;
			text-align: center;
			justify-content: center;
	`};
`
const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: evenly;
  align-items: center;
  ${media.tabletDown`
		margin-top: 2em;
	`};
`
const Arrow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.2em;
  &:hover {
    cursor: pointer;
  }
`

const Counter = styled.div``

const Slide = ({ slide, index }) => {
	const fullHeight = index === 0
	return (
		<SlideWrapper index={index}>
			<LeftColumn className={fullHeight ? 'fullHeight' : ''}>
				<SlideTitle>{slide.frontmatter.title}</SlideTitle>
				<SliderDescription dangerouslySetInnerHTML={{ __html: slide.html }}/>
				<SlideLink
					to={
						slide.frontmatter.link ? slide.frontmatter.link : slide.fields.slug
					}
				>
					{slide.frontmatter.linkTitle}
				</SlideLink>
			</LeftColumn>
			<RightColumn>
				<Img
					key={slide.id}
					fluid={slide.frontmatter.image.childImageSharp.fluid}
					alt={'Bild ' + slide.frontmatter.title}
					style={{ flex: '1', margin: '0.5em' }}
					// outerWrapperClassName={'owcn'}
					imgStyle={{ width: '100%', height: 'auto' }}
					// objectFit="cover"
					// objectPosition="50% 50%"
				/>
			</RightColumn>
		</SlideWrapper>
	)
}

const NavSlide = ({ slide }) => (
	<NavSlideWrapper>{slide.navTitle}</NavSlideWrapper>
)

class MainSlider extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			nav1: null,
			nav2: null,
			index: 0,
		}
	}

	componentDidMount() {
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
		})
	}

	handleChange(newIndex) {
		this.setState({
			index: newIndex,
		})
	}

	nextSlide() {
		this.slider2.slickNext()
	}

	prevSlide() {
		this.slider2.slickPrev()
	}

	render() {
		const settingsSlider = {
			slidesToShow: 1,
			sliderToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: this.state.nav2,
			autoplay: false,
			autoplaySpeed: 20000,
			speed: 700,
		}
		const settingsNav = {
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: this.state.nav1,
			dots: false,
			centerMode: false,
			focusOnSelect: true,
			arrows: false,
			speed: 700,
			beforeChange: (oldIndex, newIndex) => this.handleChange(newIndex),
		}
		return (
			<div>
				<GlobalSliderStyle/>
				<UpperPart>
					<Slider {...settingsSlider} ref={slider => (this.slider1 = slider)}>
						{this.props.slides.map((slide, index) => (
							<Slide
								key={slide.node.id + index}
								slide={slide.node}
								index={index + 1}
							/>
						))}
					</Slider>
				</UpperPart>
				<LowerPart>
					<LowerSliderWrapper>
						<Slider {...settingsNav} ref={slider => (this.slider2 = slider)}>
							{this.props.slides.map((slide, index) => (
								<NavSlide
									key={slide.node.id + index}
									slide={slide.node.frontmatter}
								/>
							))}
						</Slider>
					</LowerSliderWrapper>
					<Pagination>
						<Arrow onClick={() => this.prevSlide()}>
							<img src={prevArrow} alt={'Vorheriger Slide'}/>
						</Arrow>
						<Counter>
							{this.state.index + 1} / {this.props.slides.length}
						</Counter>
						<Arrow onClick={() => this.nextSlide()}>
							<img src={nextArrow} alt={'Nächster Slide'}/>
						</Arrow>
					</Pagination>
				</LowerPart>
			</div>
		)
	}
}

export default MainSlider
